"use client";

import { useTrade as useApiTrade } from "@sushiswap/react-query";
import { watchChainId } from "@wagmi/core";
import { useLogger } from "next-axiom";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useSlippageTolerance } from "src/lib/hooks/useSlippageTolerance";
import { useTokenWithCache } from "src/lib/wagmi/hooks/tokens/useTokenWithCache";
import { useClientTrade } from "src/lib/wagmi/hooks/trade/use-client-trade";
import { ChainId, TestnetChainId } from "sushi/chain";
import {
  defaultCurrency,
  defaultQuoteCurrency,
  isWNativeSupported,
} from "sushi/config";
import { Amount, Native, Type, tryParseAmount } from "sushi/currency";
import { Percent, ZERO } from "sushi/math";
import { Address, isAddress } from "viem";
import { useAccount, useChainId, useConfig, useGasPrice } from "wagmi";
import { CHAIN_ID, isSupportedChainId, isSwapApiEnabledChainId } from "../../../config";
import { useCarbonOffset } from "../../../lib/swap/useCarbonOffset";
import { useSimulateTrade } from "src/lib/hooks/useSimulateTrade";

const getTokenAsString = (token: Type | string) =>
  typeof token === "string"
    ? token
    : token.isNative
    ? "NATIVE"
    : token.wrapped.address;
const getDefaultCurrency = (chainId: number) =>
  getTokenAsString(defaultCurrency[chainId as keyof typeof defaultCurrency]);
const getQuoteCurrency = (chainId: number) =>
  getTokenAsString(
    defaultQuoteCurrency[chainId as keyof typeof defaultQuoteCurrency]
  );

interface State {
  mutate: {
    setChainId(chainId: number): void;
    setToken0(token0: Type | string): void;
    setToken1(token1: Type | string): void;
    setTokens(token0: Type | string, token1: Type | string | null): void;
    setSwapAmount(swapAmount: string | null): void;
    switchTokens(): void;
    setOutAmount(outAmount: string | null): void;
    setTokenTax(tax: Percent | false | undefined): void;
    setForceClient(forceClient: boolean): void;
  };
  state: {
    token0: Type | undefined;
    token1: Type | undefined;
    chainId: ChainId;
    swapAmountString: string;
    outAmountString: string;
    swapAmount: Amount<Type> | undefined;
    outAmount: Amount<Type> | undefined;
    recipient: string | undefined;
    tokenTax: Percent | false | undefined;
    forceClient: boolean;
  };
  isLoading: boolean;
  isToken0Loading: boolean;
  isToken1Loading: boolean;
}

const DerivedStateSimpleSwapContext = createContext<State>({} as State);

interface DerivedStateSimpleSwapProviderProps {
  children: React.ReactNode;
}

/* Parses the URL and provides the chainId, token0, and token1 globally.
 * URL example:
 * /swap?chainId=1&token0=NATIVE&token1=0x6b3595068778dd592e39a122f4f5a5cf09c90fe2
 *
 * If no chainId is provided, it defaults to current connected chainId or Ethereum if wallet is not connected.
 */
const DerivedstateSimpleSwapProvider: FC<
  DerivedStateSimpleSwapProviderProps
> = ({ children }) => {
  const { push } = useRouter();
  const _chainId = useChainId();
  const { address } = useAccount();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const [tokenTax, setTokenTax] = useState<Percent | false | undefined>(
    undefined
  );
  const [forceClient, setForceClient] = useState(false);

  // const { data: trade } = useSimpleSwapTrade();
  // Get the searchParams and complete with defaults.
  // This handles the case where some params might not be provided by the user
  const defaultedParams = useMemo(() => {
    const params = new URLSearchParams(searchParams);
    if (!params.has("chainId") || !params.get("chainId"))
      params.set(
        "chainId",
        (isSupportedChainId(_chainId)
          ? _chainId
          :CHAIN_ID 
        ).toString()
      );

    if (!params.has("token0")) {
      params.set("token0", getDefaultCurrency(Number(params.get("chainId"))));
    }
    // if (!params.has("token1")) {
    //   params.set("token1", getQuoteCurrency(Number(params.get("chainId"))));
    // }
    return params;
  }, [_chainId, searchParams]);

  // Get a new searchParams string by merging the current
  // searchParams with a provided key/value pair
  const createQueryString = useCallback(
    (values: { name: string; value: string | null }[]) => {
      const params = new URLSearchParams(defaultedParams);
      values.forEach(({ name, value }) => {
        if (value === null) {
          params.delete(name);
        } else {
          params.set(name, value);
        }
      });
      return params.toString();
    },
    [defaultedParams]
  );

  // Update the URL with a new chainId
  const setChainId = useCallback(
    (chainId: number) => {
      console.log("setChainId", chainId);
      push(
        `${pathname}?${createQueryString([
          { name: "swapAmount", value: null },
          { name: "chainId", value: chainId.toString() },
          { name: "token0", value: getDefaultCurrency(chainId) },
          { name: "token1", value: getQuoteCurrency(chainId) },
        ])}`,
        { scroll: false }
      );
    },
    [createQueryString, pathname, push]
  );

  // Switch token0 and token1
  const switchTokens = useCallback(async () => {
    const outAmountString = defaultedParams.get("outAmount");
    const swapAmountString = defaultedParams.get("swapAmount");

    // In the case of Native on top, and nothing on bottom - do nothing
    if (
      !defaultedParams.get("token1") &&
      defaultedParams.get("token0") === "NATIVE"
    ) {
      return;
    }
    push(
      `${pathname}?${createQueryString([
        { name: "token0", value: defaultedParams.get("token1") as string },
        { name: "token1", value: defaultedParams.get("token0") as string },
        {
          name: "swapAmount",
          value: outAmountString ?? null,
        },
        {
          name: "outAmount",
          value: swapAmountString ?? null,
        },
      ])}`,
      { scroll: false }
    );
  }, [createQueryString, defaultedParams, pathname, push]);

  // Update the URL with a new token0
  const setToken0 = useCallback<{ (_token0: string | Type): void }>(
    (_token0) => {
      console.log("setToken0", _token0);

      // If entity is provided, parse it to a string
      const token0 = getTokenAsString(_token0);

      // Switch tokens if the new token0 is the same as the current token1
      if (
        defaultedParams.get("token1")?.toLowerCase() === token0.toLowerCase()
      ) {
        console.log("setToken0 switch tokens");
        switchTokens();
      }

      // Push new route
      else {
        push(
          `${pathname}?${createQueryString([
            { name: "token0", value: token0 },
          ])}`,
          { scroll: false }
        );
      }
    },
    [createQueryString, defaultedParams, pathname, push, switchTokens]
  );

  // Update the URL with a new token1
  const setToken1 = useCallback<{ (_token1: string | Type): void }>(
    (_token1) => {
      console.log("setToken1", _token1);

      // If entity is provided, parse it to a string
      const token1 = getTokenAsString(_token1);

      // Trying to set native in both inputs - don't allow it
      if (
        defaultedParams.get("token0")?.toLowerCase() === "native" &&
        token1.toLowerCase() === "native"
      ) {
        return;
      }

      // Switch tokens if the new token0 is the same as the current token1
      if (
        defaultedParams.get("token0")?.toLowerCase() === token1.toLowerCase()
      ) {
        console.log("setToken1 switch tokens");
        switchTokens();
      }

      // Push new route
      else {
        push(
          `${pathname}?${createQueryString([
            { name: "token1", value: token1 },
          ])}`,
          { scroll: false }
        );
      }
    },
    [createQueryString, defaultedParams, pathname, push, switchTokens]
  );

  // Update the URL with both tokens
  const setTokens = useCallback<{
    (_token0: string | Type, _token1: string | Type | null): void;
  }>(
    (_token0, _token1) => {
      // If entity is provided, parse it to a string
      const token0 = getTokenAsString(_token0);
      const token1 = _token1 ? getTokenAsString(_token1) : null;

      push(
        `${pathname}?${createQueryString([
          { name: "token0", value: token0 },
          { name: "token1", value: token1 || null },
        ])}`,
        { scroll: false }
      );
    },
    [createQueryString, pathname, push]
  );

  // Update the URL with a new swapAmount
  const setSwapAmount = useCallback<{ (value: string | null): void }>(
    (value) => {
      push(
        `${pathname}?${createQueryString([
          { name: "swapAmount", value: value },
          { name: "outAmount", value: null },
        ])}`,
        { scroll: false }
      );
    },
    [createQueryString, pathname, push]
  );

  const setOutAmount = useCallback<{ (value: string | null): void }>(
    (value) => {
      push(
        `${pathname}?${createQueryString([
          { name: "outAmount", value: value },
          { name: "swapAmount", value: null },
        ])}`,
        { scroll: false }
      );
    },
    [createQueryString, pathname, push]
  );

  // Derive chainId from defaultedParams
  const chainId = Number(defaultedParams.get("chainId")) as Exclude<
    ChainId,
    TestnetChainId
  >;

  // console.log(_chainId, chainId)

  // const { switchChain } = useSwitchChain()

  // useEffect(() => {
  //   if (_chainId !== chainId) {
  //     // setChainId(chainId)
  //     switchChain({ chainId })
  //   }
  // }, [_chainId, chainId, switchChain, setChainId])

  const config = useConfig();

  useEffect(() => {
    const unwatch = watchChainId(config, {
      onChange: (newChainId) => {
        if (newChainId === chainId) return;
        setChainId(newChainId);
      },
    });
    return () => unwatch();
  }, [config, chainId, setChainId]);

  // Derive token0
  const { data: token0, isInitialLoading: token0Loading } = useTokenWithCache({
    chainId,
    address: defaultedParams.get("token0") as string,
    enabled: isAddress(defaultedParams.get("token0") as string),
    keepPreviousData: false,
  });

  // Derive token1
  const { data: token1, isInitialLoading: token1Loading } = useTokenWithCache({
    chainId,
    address: defaultedParams.get("token1") as string,
    enabled: isAddress(defaultedParams.get("token1") as string),
    keepPreviousData: false,
  });

  return (
    <DerivedStateSimpleSwapContext.Provider
      value={useMemo(() => {
        const swapAmountString = defaultedParams.get("swapAmount") || "";
        const outAmountString = defaultedParams.get("outAmount") || "";

        const _token0 =
          defaultedParams.get("token0") === "NATIVE" &&
          isWNativeSupported(chainId)
            ? Native.onChain(chainId)
            : token0;
        const _token1 =
          defaultedParams.get("token1") === "NATIVE" &&
          isWNativeSupported(chainId)
            ? Native.onChain(chainId)
            : token1;

        return {
          mutate: {
            setChainId,
            setToken0,
            setToken1,
            setTokens,
            switchTokens,
            setSwapAmount,
            setTokenTax,
            setForceClient,
            setOutAmount,
          },
          state: {
            recipient: address ?? "",
            chainId,
            swapAmountString,
            swapAmount: tryParseAmount(swapAmountString, _token0),
            token0: _token0,
            token1: _token1,
            tokenTax,
            forceClient,
            outAmountString,
            outAmount: tryParseAmount(outAmountString, _token1),
          },
          isLoading: token0Loading || token1Loading,
          isToken0Loading: token0Loading,
          isToken1Loading: token1Loading,
        };
      }, [
        defaultedParams,
        chainId,
        token0,
        token1,
        setChainId,
        setToken0,
        setToken1,
        setTokens,
        switchTokens,
        setSwapAmount,
        setOutAmount,
        address,
        tokenTax,
        forceClient,
        token0Loading,
        token1Loading,
      ])}
    >
      {children}
    </DerivedStateSimpleSwapContext.Provider>
  );
};

const useDerivedStateSimpleSwap = () => {
  const context = useContext(DerivedStateSimpleSwapContext);
  if (!context) {
    throw new Error(
      "Hook can only be used inside Simple Swap Derived State Context"
    );
  }

  return context;
};

const useFallback = (chainId: ChainId) => {
  const initialFallbackState = useMemo(
    () => !isSwapApiEnabledChainId(chainId),

    [chainId]
  );

  const [isFallback, setIsFallback] = useState(initialFallbackState);

  const resetFallback = useCallback(() => {
    setIsFallback(initialFallbackState);
  }, [initialFallbackState]);

  return {
    isFallback,
    setIsFallback,
    resetFallback,
  };
};

const useSimpleSwapTrade = () => {
  const log = useLogger();
  const {
    state: {
      token0,
      chainId,
      swapAmount,
      token1,
      recipient,
      tokenTax,
      forceClient,
    },
    mutate: { setTokenTax },
  } = useDerivedStateSimpleSwap();

  const { isFallback, setIsFallback, resetFallback } = useFallback(chainId);

  const [slippagePercent] = useSlippageTolerance();
  const [carbonOffset] = useCarbonOffset();
  const { data: gasPrice } = useGasPrice({ chainId });

  const useSwapApi = !isFallback && !forceClient;

  const adjustedSlippage = useMemo(
    () => (tokenTax ? slippagePercent.add(tokenTax) : slippagePercent),
    [slippagePercent, tokenTax]
  );

  const apiTrade = useApiTrade({
    chainId,
    fromToken: token0,
    toToken: token1,
    amount: swapAmount,
    slippagePercentage: adjustedSlippage.toFixed(2),
    gasPrice,
    recipient: recipient as Address,
    enabled: Boolean(useSwapApi && swapAmount?.greaterThan(ZERO)),
    carbonOffset,
    onError: () => {
      log.error("api trade error");
      setIsFallback(true);
    },
    tokenTax,
  });

  // const clientTrade = useClientTrade({
  //   chainId,
  //   fromToken: token0,
  //   toToken: token1,
  //   amount: swapAmount,
  //   slippagePercentage: adjustedSlippage.toFixed(2),
  //   gasPrice,
  //   recipient: recipient as Address,
  //   enabled: Boolean(!useSwapApi && swapAmount?.greaterThan(ZERO)),
  //   carbonOffset,
  //   onError: () => {
  //     log.error("client trade error");
  //   },
  //   tokenTax,
  // });

  const config = useConfig();

  // Reset the fallback on network switch
  useEffect(() => {
    const unwatch = watchChainId(config, {
      onChange: (newChainId) => {
        if (newChainId) {
          resetFallback();
        }
      },
    });
    return () => unwatch();
  }, [config, resetFallback]);

  // Write the useSwapApi value to the window object so it can be logged to GA
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.useSwapApi = useSwapApi;
    }
  }, [useSwapApi]);

  // Reset tokenTax when token0 or token1 changes
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    setTokenTax(undefined);
  }, [token0, token1, setTokenTax]);

  return apiTrade;
};

const useOutSimpleSwapTrade = () => {
  const log = useLogger();
  const {
    state: {
      token0,
      chainId,
      outAmount,
      token1,
      recipient,
      tokenTax,
      forceClient,
    },
    mutate: { setTokenTax },
  } = useDerivedStateSimpleSwap();

  const { isFallback, setIsFallback, resetFallback } = useFallback(chainId);

  const [slippagePercent] = useSlippageTolerance();
  const [carbonOffset] = useCarbonOffset();
  const { data: gasPrice } = useGasPrice({ chainId });

  const useSwapApi = !isFallback && !forceClient;

  const adjustedSlippage = useMemo(
    () => (tokenTax ? slippagePercent.add(tokenTax) : slippagePercent),
    [slippagePercent, tokenTax]
  );

  const apiTrade = useApiTrade({
    chainId,
    fromToken: token0,
    toToken: token1,
    amount: outAmount,
    slippagePercentage: adjustedSlippage.toFixed(2),
    gasPrice,
    recipient: recipient as Address,
    enabled: Boolean(useSwapApi && outAmount?.greaterThan(ZERO)),
    carbonOffset,
    isOut: true,
    onError: () => {
      log.error("api trade error");
      setIsFallback(true);
    },
    tokenTax,
  });

  const config = useConfig();

  // Reset the fallback on network switch
  useEffect(() => {
    const unwatch = watchChainId(config, {
      onChange: (newChainId) => {
        if (newChainId) {
          resetFallback();
        }
      },
    });
    return () => unwatch();
  }, [config, resetFallback]);

  // Write the useSwapApi value to the window object so it can be logged to GA
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.useSwapApi = useSwapApi;
    }
  }, [useSwapApi]);

  // Reset tokenTax when token0 or token1 changes
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    setTokenTax(undefined);
  }, [token0, token1, setTokenTax]);

  return apiTrade;
};

export {
  DerivedstateSimpleSwapProvider,
  useDerivedStateSimpleSwap,
  useOutSimpleSwapTrade,
  useFallback,
  useSimpleSwapTrade,
};
