"use client";

import { useIsMounted } from "@sushiswap/hooks";
import { Collapsible, Explainer, SkeletonBox, classNames } from "@sushiswap/ui";
import React, { FC, useMemo, useState } from "react";
import { AddressToEnsResolver } from "src/lib/wagmi/components/account/AddressToEnsResolver";
import { Chain, ChainId } from "sushi/chain";
import { Native, tryParseAmount, WETH9_ADDRESS } from "sushi/currency";
import { shortenAddress } from "sushi/format";
import { ZERO } from "sushi/math";
import { Address, isAddress } from "viem";
import { useAccount } from "wagmi";

import { TradeRoutePathView } from "../trade-route-path-view";
import {
  useDerivedStateSimpleSwap,
  useFallback,
  useOutSimpleSwapTrade,
  useSimpleSwapTrade,
} from "./derivedstate-simple-swap-provider";
import { motion } from "framer-motion";
import { usePrice } from "@sushiswap/react-query";

export const SimpleSwapTradeStats: FC = () => {
  const { address } = useAccount();
  const isMounted = useIsMounted();
  const {
    state: { chainId, swapAmountString, outAmountString, recipient, token0 },
  } = useDerivedStateSimpleSwap();
  const { isLoading, data: tradeIn } = useSimpleSwapTrade();
  const { data: tradeOut } = useOutSimpleSwapTrade();
  const trade = tradeIn ?? tradeOut;

  const parsedValue = useMemo(
    () => tryParseAmount(trade?.amountIn?.toSignificant(), token0),
    [token0, trade]
  );

  const { data: price, isInitialLoading: isPriceLoading } = usePrice({
    chainId: chainId,
    address: token0?.isNative
      ? WETH9_ADDRESS[chainId]
      : token0?.id.split(":")[1],
  });

  const loading = Boolean(isLoading && !trade);
  const [isOpen, setIsOpen] = useState(true);

  if (!tradeIn && !tradeOut) return <></>;

  const [big, portion] = (
    parsedValue && price
      ? `${price.asFraction
          .multiply(parsedValue)
          .divide(10 ** parsedValue.currency.decimals)
          .toFixed(2)}`
      : "0.00"
  ).split(".");

  return (
    <Collapsible
      open={
        (+swapAmountString > 0 || +outAmountString > 0) &&
        trade?.route?.status !== "NoWay"
      }
    >
      <div className="w-full px-2 flex flex-col gap-1">
        <div className="flex items-center justify-between gap-2 font-semibold">
          {loading || !trade?.amountOut ? (
            <SkeletonBox className="h-4 py-0.5 w-[120px]" />
          ) : (
            <div>
              <span className="text-sm text-white/80 ">
                1 {trade?.route?.fromToken.symbol} ={" "}
                {trade?.swapPriceInverse?.toSignificant(6)}{" "}
                {trade.route?.toToken.symbol}
              </span>

              <span className="text-sm text-white/40">
                {" "}
                (${big}.{portion})
              </span>
            </div>
          )}
          <motion.div
            className="cursor-pointer"
            onClick={() => setIsOpen(!isOpen)}
            animate={{ rotate: isOpen ? 180 : 0 }}
            transition={{ duration: 0.3 }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-5 h-5 text-white"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m19.5 8.25-7.5 7.5-7.5-7.5"
              />
            </svg>
          </motion.div>
        </div>

        {isOpen && (
          <>
            <div className="flex justify-between items-center gap-2">
              <span className="text-sm text-white/50 ">Price impact</span>
              <span className="text-sm text-white/50 text-right ">
                {loading || !trade?.priceImpact ? (
                  <SkeletonBox className="h-4 py-0.5 w-[40px]" />
                ) : trade?.priceImpact ? (
                  `${
                    trade?.priceImpact?.lessThan(ZERO)
                      ? "+"
                      : trade?.priceImpact?.greaterThan(ZERO)
                      ? "-"
                      : ""
                  }${Math.abs(Number(trade?.priceImpact?.toFixed(2)))}%`
                ) : null}
              </span>
            </div>

            <div className="flex justify-between items-center gap-2">
              <span className="text-sm text-white/50 ">Est. received</span>
              <span className="text-sm text-white/50 text-right ">
                {loading || !trade?.amountOut ? (
                  <SkeletonBox className="h-4 py-0.5 w-[120px]" />
                ) : (
                  `${trade?.amountOut?.toSignificant(6) ?? "0.00"} ${
                    trade?.amountOut?.currency?.symbol ?? ""
                  }`
                )}
              </span>
            </div>

            <div className="flex justify-between items-center gap-2">
              <span className="text-sm text-white/50 ">Min. received</span>
              <span className="text-sm text-white/50 text-right ">
                {loading || !trade?.minAmountOut ? (
                  <SkeletonBox className="h-4 py-0.5 w-[100px]" />
                ) : (
                  `${trade?.minAmountOut?.toSignificant(6) ?? "0.00"} ${
                    trade?.amountOut?.currency?.symbol ?? ""
                  }`
                )}
              </span>
            </div>

            <div className="flex justify-between items-center">
              <span className="text-sm text-white/50 ">Network fee</span>
              <span className="text-sm text-white/50 text-right ">
                {loading || !trade?.gasSpent || trade.gasSpent === "0" ? (
                  <SkeletonBox className="h-4 py-0.5 w-[120px]" />
                ) : trade?.gasSpent ? (
                  `${trade.gasSpent} ${Native.onChain(chainId).symbol} ${
                    trade?.gasSpentUsd ? `($${trade.gasSpentUsd})` : ""
                  }`
                ) : null}
              </span>
            </div>

            <div className="flex justify-between items-center">
              <span className="text-sm text-white/50 ">Order Routing</span>
              <span className="text-sm font-semibold text-white/50 text-right ">
                {loading || !trade ? (
                  <SkeletonBox className="h-4 py-0.5 w-[120px]" />
                ) : (
                  "Blackhole API"
                )}
              </span>
            </div>

            <div className="flex justify-between items-center gap-2">
              <span className="text-sm text-white/50 ">Fee (0.25%)</span>
              <span className="text-sm text-white/50 text-right ">
                {loading || !trade?.fee ? (
                  <SkeletonBox className="h-4 py-0.5 w-[100px]" />
                ) : (
                  `${trade?.fee}`
                )}
              </span>
            </div>

            <div className="flex justify-between items-center">
              <span className="text-sm text-white/50 ">Route</span>
              <span className="text-sm text-white/50 text-right ">
                {loading || !trade ? (
                  <SkeletonBox className="h-4 py-0.5 w-[40px]" />
                ) : (
                  <TradeRoutePathView trade={trade}>
                    <button type="button" className="text-sm text-white/50">
                      View
                    </button>
                  </TradeRoutePathView>
                )}
              </span>
            </div>

            {recipient && isAddress(recipient) && isMounted && (
              <div className="flex justify-between items-center border-t border-white/50 mt-2 pt-2">
                <span className="font-semibold text-[12px] text-white/80">
                  Recipient
                </span>
                <span className="font-semibold text-white/50 text-right ">
                  <a
                    target="_blank"
                    href={Chain.from(chainId)?.getAccountUrl(recipient)}
                    className={classNames(
                      address !== recipient
                        ? "text-yellow-600"
                        : "text-white/80",
                      "transition-all flex gap-1 items-center font-bold text-[12px]"
                    )}
                    rel="noreferrer"
                  >
                    <AddressToEnsResolver address={recipient as Address}>
                      {({ isLoading, data }) => {
                        return (
                          <>
                            {isLoading || !data
                              ? shortenAddress(recipient)
                              : data}
                          </>
                        );
                      }}
                    </AddressToEnsResolver>
                    {address !== recipient && (
                      <Explainer>
                        Recipient is different from the connected wallet
                        address. If this is expected, ignore this warning.
                      </Explainer>
                    )}
                  </a>
                </span>
              </div>
            )}
          </>
        )}
      </div>
    </Collapsible>
  );
};
