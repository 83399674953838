export default function SwapIcon() {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 1.25L1 4.25M1 4.25L4 7.25M1 4.25H13M10 14.75L13 11.75M13 11.75L10 8.75M13 11.75H1"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
