"use client";

import { DialogTrigger } from "@sushiswap/ui";
import { Button } from "@sushiswap/ui";
import React, { FC, useEffect, useMemo, useState } from "react";
import { Checker } from "src/lib/wagmi/systems/Checker";
import {
  ROUTE_PROCESSOR_5_ADDRESS,
  isRouteProcessor5ChainId,
} from "sushi/config";
import { Native } from "sushi/currency";
import { ZERO } from "sushi/math";
import { APPROVE_TAG_SWAP } from "../../../lib/constants";
import { usePersistedSlippageError } from "../../../lib/hooks";
import { warningSeverity } from "../../../lib/swap/warningSeverity";
import {
  useDerivedStateSimpleSwap,
  useOutSimpleSwapTrade,
  useSimpleSwapTrade,
} from "./derivedstate-simple-swap-provider";
import { SimpleSwapTradeReviewDialog } from "./simple-swap-trade-review-dialog";
import { useIsSwapMaintenance } from "./use-is-swap-maintenance";
import SimpleSwapRewardsDisplay from "./simple-swap-rewards-display";
import { useUser } from "src/providers/user-provider";
import useBlackholePoints, {
  BlackholePointsType,
} from "src/lib/hooks/useBlackholePoints";

export const SimpleSwapTradeButton: FC = () => {
  return (
    <>
      <SimpleSwapTradeReviewDialog>
        {({ error, isSuccess }) => (
          <_SimpleSwapTradeButton error={error} isSuccess={isSuccess} />
        )}
      </SimpleSwapTradeReviewDialog>
    </>
  );
};

export const _SimpleSwapTradeButton: FC<{
  error: Error | null;
  isSuccess: boolean;
}> = ({ error, isSuccess }) => {
  const { data: maintenance } = useIsSwapMaintenance();
  const { isSlippageError } = usePersistedSlippageError({ isSuccess, error });
  const { data: trade } = useSimpleSwapTrade();
  const { data: tradeOut } = useOutSimpleSwapTrade();

  const { banned, username } = useUser();
  // const [checked, setChecked] = useState(false);

  const {
    state: {
      swapAmount,
      swapAmountString,
      outAmountString,
      outAmount,
      chainId,
      token0,
      token1,
    },
  } = useDerivedStateSimpleSwap();

  const isWrap =
    token0?.isNative &&
    token1?.wrapped.address === Native.onChain(chainId).wrapped.address;
  const isUnwrap =
    token1?.isNative &&
    token0?.wrapped.address === Native.onChain(chainId).wrapped.address;

  // Reset
  // useEffect(() => {
  //   if (warningSeverity(trade?.priceImpact) <= 3) {
  //     setChecked(false);
  //   }
  // }, [trade?.priceImpact]);

  // Get the highest of the two incentivization points, default to 10 otherwise

  const parsedTradeAmount = Number(trade?.amountOut?.toSignificant() ?? 0);

  const parsedAmount0 =
    parsedTradeAmount > 0 ? parsedTradeAmount : Number(outAmountString);

  const { data: blackholePoints } = useBlackholePoints({
    action: BlackholePointsType.Swap,
    token0,
    token1,
    amount0: parsedAmount0,
  });

  const renderPoints = useMemo(() => {
    return (
      <div className="mb-2">
        <SimpleSwapRewardsDisplay points={blackholePoints?.points ?? 0} />
      </div>
    );
  }, [blackholePoints]);

  return (
    <>
      {banned ? (
        <>
          <div className="rounded-md mt-2 border border-red-500 bg-red-50 p-4 text-sm font-medium text-red-800">
            Sorry {username}, you are banned from the app.
          </div>
        </>
      ) : (
        <>
          <div className="pt-2">
            {/* Price Impact Warning */}
            {warningSeverity(trade?.priceImpact) >= 3 && (
              <div className="h-[48px] flex items-center justify-between px-4 mb-2 rounded-lg bg-bhModal font-semibold text-[12px] border border-[#E5574C]/30">
                <div className="text-white/80">Price impact warning</div>
                <div className="text-[#E5574C]">{`${
                  trade?.priceImpact?.lessThan(ZERO)
                    ? "+"
                    : trade?.priceImpact?.greaterThan(ZERO)
                    ? "-"
                    : ""
                }${Math.abs(Number(trade?.priceImpact?.toFixed(2)))}%`}</div>
              </div>
            )}

            {/* Reward Display */}
            {renderPoints}
            {/* <div className="mb-2">
              <SimpleSwapRewardsDisplay points={points ?? 0} />
            </div> */}

            {/* Swap Button */}
            <div>
              <Checker.Guard
                guardWhen={maintenance}
                guardText="Maintenance in progress"
              >
                <Checker.Connect>
                  <Checker.Network chainId={chainId}>
                    <Checker.Amounts
                      chainId={chainId}
                      amounts={[swapAmount, outAmount]}
                    >
                      <Checker.ApproveERC20
                        id="approve-erc20"
                        enabled={
                          !!trade?.amountOut?.toSignificant() ||
                          !!tradeOut?.amountOut?.toSignificant()
                        }
                        amount={trade?.amountIn ?? tradeOut?.amountIn}
                        contract={
                          isRouteProcessor5ChainId(chainId)
                            ? ROUTE_PROCESSOR_5_ADDRESS[chainId]
                            : undefined
                        }
                      >
                        <Checker.Success tag={APPROVE_TAG_SWAP}>
                          <DialogTrigger asChild>
                            <Button
                              size="xl"
                              disabled={Boolean(
                                isSlippageError ||
                                  error ||
                                  (!trade && !tradeOut) ||
                                  +(trade?.fee ?? "0") < 0 ||
                                  trade?.route?.status === "NoWay" ||
                                  (+swapAmountString === 0 &&
                                    +outAmountString === 0)
                              )}
                              color={
                                warningSeverity(trade?.priceImpact) >= 3
                                  ? "red"
                                  : "blue"
                              }
                              fullWidth
                              testId="swap"
                            >
                              {/* warningSeverity(trade?.priceImpact) > 3
                           ? "Price impact too high" : */}
                              {trade?.route?.status === "NoWay" ||
                              (!trade && !tradeOut)
                                ? "No trade found"
                                : isWrap
                                ? "Wrap"
                                : isUnwrap
                                ? "Unwrap"
                                : "Swap"}
                            </Button>
                          </DialogTrigger>
                        </Checker.Success>
                      </Checker.ApproveERC20>
                    </Checker.Amounts>
                  </Checker.Network>
                </Checker.Connect>
              </Checker.Guard>
            </div>
          </div>
        </>
      )}
    </>
  );
};
