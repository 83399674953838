import(/* webpackMode: "eager", webpackExports: ["DerivedstateSimpleSwapProvider"] */ "/Users/timothykrell/Code/superfarm/blackhole/blackhole-dex-ui-shared/apps/web/src/ui/swap/simple/derivedstate-simple-swap-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SimpleSwapSettingsOverlay"] */ "/Users/timothykrell/Code/superfarm/blackhole/blackhole-dex-ui-shared/apps/web/src/ui/swap/simple/simple-swap-settings-overlay.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SimpleSwapSwitchTokensButton"] */ "/Users/timothykrell/Code/superfarm/blackhole/blackhole-dex-ui-shared/apps/web/src/ui/swap/simple/simple-swap-switch-tokens-button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SimpleSwapToken0Input"] */ "/Users/timothykrell/Code/superfarm/blackhole/blackhole-dex-ui-shared/apps/web/src/ui/swap/simple/simple-swap-token0-input.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SimpleSwapToken1Input"] */ "/Users/timothykrell/Code/superfarm/blackhole/blackhole-dex-ui-shared/apps/web/src/ui/swap/simple/simple-swap-token1-input.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SimpleSwapTradeButton"] */ "/Users/timothykrell/Code/superfarm/blackhole/blackhole-dex-ui-shared/apps/web/src/ui/swap/simple/simple-swap-trade-button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SimpleSwapTradeStats"] */ "/Users/timothykrell/Code/superfarm/blackhole/blackhole-dex-ui-shared/apps/web/src/ui/swap/simple/simple-swap-trade-stats.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SwapMaintenanceMessage"] */ "/Users/timothykrell/Code/superfarm/blackhole/blackhole-dex-ui-shared/apps/web/src/ui/swap/simple/swap-maintenance-message.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SwapModeButtons"] */ "/Users/timothykrell/Code/superfarm/blackhole/blackhole-dex-ui-shared/apps/web/src/ui/swap/swap-mode-buttons.tsx");
