"use client";

import { Web3Input } from "src/lib/wagmi/components/web3-input";
import { isWNativeSupported } from "sushi/config";
import {
  useDerivedStateSimpleSwap,
  useSimpleSwapTrade,
} from "./derivedstate-simple-swap-provider";
import { useEffect } from "react";

export const SimpleSwapToken1Input = () => {
  const {
    state: { chainId, token1, outAmountString },
    mutate: { setToken1, setSwapAmount, setOutAmount },
    isToken1Loading: tokenLoading,
  } = useDerivedStateSimpleSwap();

  const {
    isInitialLoading: isLoading,
    isFetching,
    data: trade,
  } = useSimpleSwapTrade();

  return (
    <Web3Input.Currency
      id="swap-to"
      type="OUTPUT"
      className="border border-accent p-3 !bg-[#0D0D0D] rounded-xl"
      value={
        outAmountString?.length
          ? outAmountString
          : trade?.amountOut?.toSignificant() ?? ""
      }
      chainId={chainId}
      onSelect={setToken1}
      onChange={(e) => {
        setOutAmount(e);
      }}
      currency={token1}
      loading={isLoading}
      fetching={isFetching}
      disableMaxButton
      currencyLoading={tokenLoading}
      allowNative={isWNativeSupported(chainId)}
      helperText="You receive"
      // priceImpact={trade?.priceImpact}
    />
  );
};
