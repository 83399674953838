export default function CircleDesign() {
  return (
    <svg
      width="50"
      height="49"
      viewBox="0 0 50 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36.2937 0.0736466C39.3411 -0.13861 45.6391 0.022098 48.1225 1.71712C35.19 -0.13861 21.0112 3.80633 13.4579 15.0256C-2.55227 38.8105 21.93 66.9223 44.3443 52.1614C33.3706 64.7543 13.9037 55.1815 8.53055 41.6456C0.90751 22.4364 16.3658 1.46241 36.2967 0.0736466H36.2937Z"
        fill="white"
      />
      <path
        d="M38.2655 3.02952C47.6382 2.24721 58.3116 5.16725 64.7157 12.3961C56.2831 7.51723 49.3423 4.04532 39.251 4.67299C6.51194 6.71066 0.959922 52.3458 36.1308 53.8013C24.8994 57.2156 11.876 46.5451 10.5024 35.3986C8.398 18.315 21.5912 4.42435 38.2685 3.02952H38.2655Z"
        fill="white"
      />
      <path
        d="M38.9238 6.64772C53.0146 5.54399 67.8544 13.7613 72.9304 27.1881C66.1291 18.7191 58.5303 10.3622 47.1382 8.62171C19.2659 4.35838 2.96152 36.0513 28.2443 50.5211C4.17745 42.1764 12.6889 8.70358 38.9238 6.65075V6.64772Z"
        fill="white"
      />
      <path
        d="M39.5819 10.2626C56.3653 8.99206 71.857 23.6772 74.2464 36.7158C74.483 38.0136 74.8771 45.6033 73.919 43.2897C73.5915 41.4279 73.261 39.5661 72.9335 37.7043C68.1213 21.2271 52.3082 8.36136 34.6545 12.8946C21.1975 16.3483 14.7631 32.6526 23.32 43.9507C22.8258 45.1939 14.6024 36.2943 19.3781 23.9046C22.3679 16.1481 31.5677 10.872 39.5849 10.2656L39.5819 10.2626Z"
        fill="white"
      />
      <path
        d="M10.9939 10.917C12.2219 10.8443 9.16849 14.7073 8.52868 16.0112C6.61534 19.9046 4.87181 24.4681 4.58678 28.8285C2.74924 56.8463 39.2361 72.8626 49.7671 47.0673C49.6488 54.275 42.2744 60.6669 35.3094 61.5281C9.68093 64.6968 -8.48822 31.0208 10.9939 10.9201V10.917Z"
        fill="white"
      />
      <path
        d="M38.9231 14.2039C61.771 11.7296 80.1554 39.3867 68.4965 59.2266C70.2339 51.4853 71.3407 44.8659 68.9907 37.0458C61.5678 12.3422 25.0961 5.7622 21.6757 35.7298C19.729 24.7501 28.2253 15.3653 38.9261 14.2039H38.9231Z"
        fill="white"
      />
      <path
        d="M38.9238 18.1487C63.2666 15.1498 77.5272 46.6638 62.087 65.6365C61.3835 66.5038 54.9582 72.8927 54.2002 71.5524C59.1882 66.9313 63.9458 61.5642 65.7014 54.7933C71.7993 31.2601 49.3941 11.7992 31.3644 22.4241C29.8301 23.3278 23.3441 29.3013 28.2442 23.5734C30.3092 21.1567 35.7702 18.5399 38.9238 18.1517V18.1487Z"
        fill="white"
      />
      <path
        d="M40.8939 21.4352C59.1904 20.3345 71.8227 44.174 60.4428 62.0217C54.1691 71.8583 40.8727 77.9531 29.3927 75.3302C24.9292 74.3113 33.7197 74.8875 35.9635 74.6722C42.028 74.0961 48.7626 71.4792 53.3776 67.4433C71.5438 51.5544 62.6533 20.9076 37.2765 22.4207C38.4803 22.0932 39.6871 21.7627 40.8909 21.4352H40.8939Z"
        fill="white"
      />
      <path
        d="M45.8213 25.3789C62.5804 30.2911 65.3185 51.8685 54.1993 63.9914C42.4828 76.7662 18.9556 75.4896 8.19727 62.0204C8.25185 61.8567 8.30643 61.693 8.36101 61.5262C20.2049 72.985 42.2796 75.526 53.5413 62.0204C63.7963 49.7217 59.7999 32.6866 45.8213 25.3789Z"
        fill="white"
      />
      <path
        d="M0.478543 29.3223C0.642284 29.3768 0.806025 29.4314 0.972798 29.486C-0.340161 48.7741 18.4385 67.7468 37.9388 63.1711C45.1191 61.4852 50.938 55.1023 52.2327 47.8886C52.536 46.1967 52.6906 36.5572 53.5457 43.2887C54.7071 52.4249 48.9519 61.279 40.2372 64.1566C19.8818 70.8791 -3.66349 50.3114 0.478543 29.3223Z"
        fill="white"
      />
      <path
        d="M51.4063 31.9512C65.112 47.8613 52.8982 68.7656 33.0067 69.4145C19.8468 69.845 3.39996 59.6203 0.640625 46.2451C1.09546 45.3506 3.70621 53.0586 10.3347 58.8987C33.0219 78.8841 67.2558 61.6368 51.4063 31.9512Z"
        fill="white"
      />
    </svg>
  );
}
