export default function PointsIcon() {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_935_7232)">
        <path
          d="M5 6.25C5.69036 6.25 6.25 5.69036 6.25 5C6.25 4.30964 5.69036 3.75 5 3.75C4.30964 3.75 3.75 4.30964 3.75 5C3.75 5.69036 4.30964 6.25 5 6.25Z"
          stroke="white"
          strokeWidth="0.833333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.91536 2.91667C8.3756 2.91667 8.7487 2.54357 8.7487 2.08333C8.7487 1.6231 8.3756 1.25 7.91536 1.25C7.45513 1.25 7.08203 1.6231 7.08203 2.08333C7.08203 2.54357 7.45513 2.91667 7.91536 2.91667Z"
          stroke="white"
          strokeWidth="0.833333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.08333 8.7487C2.54357 8.7487 2.91667 8.3756 2.91667 7.91536C2.91667 7.45513 2.54357 7.08203 2.08333 7.08203C1.6231 7.08203 1.25 7.45513 1.25 7.91536C1.25 8.3756 1.6231 8.7487 2.08333 8.7487Z"
          stroke="white"
          strokeWidth="0.833333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.33398 9.12451C5.13513 9.25614 5.95729 9.15086 6.69941 8.82158C7.44154 8.4923 8.07129 7.95339 8.51129 7.27106C8.95129 6.58874 9.18236 5.79273 9.1761 4.98086C9.16984 4.16899 8.92653 3.37664 8.47607 2.70117"
          stroke="white"
          strokeWidth="0.833333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.62379 0.875877C4.82591 0.754035 4.00982 0.86655 3.27469 1.19975C2.53955 1.53295 1.91701 2.0725 1.48272 2.75282C1.04843 3.43314 0.821075 4.22496 0.828292 5.03205C0.835508 5.83914 1.07698 6.62676 1.52337 7.29921"
          stroke="white"
          strokeWidth="0.833333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_935_7232">
          <rect width="10" height="10" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
