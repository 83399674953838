export default function GasIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.4" clipPath="url(#clip0_1238_839)">
        <path
          d="M3 14.6667H11"
          stroke="white"
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.66602 6H10.3327"
          stroke="white"
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.3327 14.6667V2.66671C10.3327 2.31309 10.1922 1.97395 9.94216 1.7239C9.69211 1.47385 9.35297 1.33337 8.99935 1.33337H4.99935C4.64573 1.33337 4.30659 1.47385 4.05654 1.7239C3.80649 1.97395 3.66602 2.31309 3.66602 2.66671V14.6667"
          stroke="white"
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.334 8.66671H11.6673C12.0209 8.66671 12.3601 8.80718 12.6101 9.05723C12.8602 9.30728 13.0007 9.64642 13.0007 10V11.3334C13.0007 11.687 13.1411 12.0261 13.3912 12.2762C13.6412 12.5262 13.9804 12.6667 14.334 12.6667C14.6876 12.6667 15.0267 12.5262 15.2768 12.2762C15.5268 12.0261 15.6673 11.687 15.6673 11.3334V6.55337C15.6675 6.37743 15.6328 6.2032 15.5653 6.04073C15.4978 5.87825 15.3988 5.73075 15.274 5.60671L13.0007 3.33337"
          stroke="white"
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1238_839">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
