"use client";

// Similar to PathnameButton but non-interactive

import { Button, ButtonProps } from "@sushiswap/ui";
import { usePathname } from "next/navigation";
import { forwardRef } from "react";

interface Props {
  pathname: string;
  children: React.ReactNode;
}

function PathnameBadge({ pathname, ...props }: Props) {
  const _pathname = usePathname();
  const isMatch =
    _pathname === pathname || _pathname === pathname.replace("%3A", ":");
  return (
    <div
      {...props}
      className={`text-white px-3 py-1 flex items-center text-sm rounded-full cursor-default font-medium ${
        isMatch ? "bg-white/20" : "bg-transparent"
      }`}
    />
  );
}

PathnameBadge.displayName = "PathnameBadge";

export { PathnameBadge };
