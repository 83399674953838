"use client";

import { Web3Input } from "src/lib/wagmi/components/web3-input";
import { isWNativeSupported } from "sushi/config";
import {
  useDerivedStateSimpleSwap,
  useOutSimpleSwapTrade,
} from "./derivedstate-simple-swap-provider";
import { useUser } from "src/providers/user-provider";
import { useEffect } from "react";
import { usePrevious } from "@sushiswap/hooks";

export const SimpleSwapToken0Input = () => {
  const { username } = useUser();
  const previousUsername = usePrevious(username);
  const {
    state: { swapAmountString, chainId, token0 },
    mutate: { setSwapAmount, setToken0 },
    isToken0Loading: isLoading,
  } = useDerivedStateSimpleSwap();

  const { data: trade } = useOutSimpleSwapTrade();

  // Reset values when user logs out
  useEffect(() => {
    if (previousUsername && !username) {
      setSwapAmount("");
    }
  }, [username, previousUsername, setSwapAmount]);

  return (
    <Web3Input.Currency
      id="swap-from"
      type="INPUT"
      className=""
      chainId={chainId}
      onSelect={setToken0}
      value={
        swapAmountString?.length
          ? swapAmountString
          : trade?.amountIn?.toSignificant() ?? ""
      }
      onChange={(e) => {
        setSwapAmount(e);
      }}
      currency={token0}
      loading={isLoading}
      currencyLoading={isLoading}
      allowNative={isWNativeSupported(chainId)}
      helperText="You pay"
    />
  );
};
