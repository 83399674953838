"use client";

import {
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@sushiswap/ui";

import { ShuffleIcon } from "@sushiswap/ui/icons/ShuffleIcon";
import Link from "next/link";

import { PathnameButton } from "../pathname-button";
import SwapIcon from "./simple/_icons/swap-icon";
import { PathnameBadge } from "../pathname-badge";

export const SwapModeButtons = () => {
  return (
    <div className="flex gap-2">
      <PathnameBadge pathname="/swap">
        <SwapIcon />
        <span className="pl-1">Swap</span>
      </PathnameBadge>

      {/* Move back to PathnameButton when we have multiple swap types */}
      {/* <Link href="/swap">
        <PathnameButton
          className="bg-white dark:bg-[#0D0D0D]"
          pathname="/swap"
          size="sm"
        >
          <SwapIcon /> Swap
        </PathnameButton>
      </Link> */}
    </div>
  );
};
