export default function UpDownArrow() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.21289 10.5703L4.78432 13.1417L7.35575 10.5703"
        stroke="white"
        strokeWidth="1.28571"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.78516 13.1431V2.85742"
        stroke="white"
        strokeWidth="1.28571"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.7854 5.4269L11.214 2.85547L8.64258 5.4269"
        stroke="white"
        strokeWidth="1.28571"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.2129 2.85547V13.1412"
        stroke="white"
        strokeWidth="1.28571"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
