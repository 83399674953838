import { useEffect, useState } from "react";
import { Type, WETH9_ADDRESS } from "sushi/currency";
import { useAccount, useChainId } from "wagmi";
import { BlackholePointsConfig } from "@prisma/client";
import { useQuery } from "@tanstack/react-query";
import { ChainId } from "sushi";
import { CHAIN_ID } from "src/config";

export enum BlackholePointsType {
  Swap,
  AddLiquidity,
}

type BlackholePointsProps = {
  action: BlackholePointsType;
  token0?: Type;
  token1?: Type;
  amount0: number;
  amount1?: number;
};

const BLACKHOLE_API = process.env.NEXT_PUBLIC_BLACKHOLE_API_URL;

const useBlackholePoints = (props: BlackholePointsProps) => {
  const { address } = useAccount();

  const chainId = useChainId();
  const calculatePoints = async () => {
    try {
      const tokens = getTokens(chainId);

      if (!address) return;

      if (!props.token0 || !props.token1) {
        return 0;
      }

      if (!tokens.length) {
        return 0;
      }

      const promises = [
        fetch(`${BLACKHOLE_API}/price`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ tokens }),
        }),
        fetch(`${BLACKHOLE_API}/points/${address}`),
      ];

      const [pricesResponse, statsResponse] = await Promise.all(promises);

      const [prices, stats]: [
        { prices: Record<string, number> },
        {
          totalUsdTraded: number;
          pointsConfig: BlackholePointsConfig;
          hasReferral: boolean;
        }
      ] = await Promise.all([pricesResponse.json(), statsResponse.json()]);
      let totalIncetivizationPoints =
        (props.token0.incetivizationPoints ?? 0) +
        (props.token1.incetivizationPoints ?? 0);

      switch (props.action) {
        case BlackholePointsType.Swap: {
          const usdPrice =
            prices.prices[getTokenAddresses(chainId, props.token1) ?? ""] ?? 0;

          const usdAmount = props.amount0 * usdPrice;

          let incvPoints =
            props.token1?.incetivizationPoints === 0
              ? 1
              : props.token1?.incetivizationPoints ?? 1;

          let points =
            incvPoints * usdAmount * (stats.pointsConfig.swapMultiplier ?? 0);

          if (
            stats.totalUsdTraded < stats.pointsConfig.perswap &&
            stats.totalUsdTraded + usdAmount > stats.pointsConfig.perswap &&
            stats.hasReferral
          ) {
            points += stats.pointsConfig.referralPoints;
          }

          return points;
        }
        case BlackholePointsType.AddLiquidity: {
          if (!props.amount1) {
            return 0;
          }

          const token0Usd =
            prices.prices[getTokenAddresses(chainId, props.token0) ?? ""] ?? 0;

          const token1Usd =
            prices.prices[getTokenAddresses(chainId, props.token1) ?? ""] ?? 0;

          const totalPoints =
            ((token0Usd * props.amount0 + token1Usd * props.amount1) /
              stats.pointsConfig.perlp) *
            (stats.pointsConfig.lpDepositMultiplier *
              stats.pointsConfig.perlp) *
            stats.pointsConfig.lpDepositMultiplier *
            (totalIncetivizationPoints === 0 ? 1 : totalIncetivizationPoints);

          return totalPoints;
        }
      }
    } catch (error) {}
  };

  const getTokens = (chainId: number): string[] => {
    const tokens: (string | undefined)[] = [];

    if (props.token0) {
      tokens.push(getTokenAddresses(chainId, props.token0));
    }

    if (props.token1) {
      tokens.push(getTokenAddresses(chainId, props.token1));
    }

    return tokens.filter((t) => !!t) as string[];
  };

  const getTokenAddresses = (chainId: number, t?: Type) => {
    if (!t) return undefined;

    if (t.id.includes(":")) {
      const token = t.id.split(":")[1];

      //TODO:BHOLE check what will be native address on mainnet and send that
      if (token === "NATIVE")
        return WETH9_ADDRESS[CHAIN_ID].toLocaleLowerCase();
      return token;
    }

    return t.id;
  };

  return useQuery({
    queryKey: [
      "useBlackholePoints",
      {
        ...props,
      },
    ],
    queryFn: async () => {
      const points = await calculatePoints();

      return { points };
    },
    refetchInterval: 10000,
    enabled: true,
  });
};

export default useBlackholePoints;
