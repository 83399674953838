"use client";

import { createErrorToast, createToast } from "@sushiswap/notifications";
import { usePrice, UseTradeReturn } from "@sushiswap/react-query";
import {
  BrowserEvent,
  InterfaceElementName,
  InterfaceModalName,
  SwapEventName,
  Trace,
  TraceEvent,
  sendAnalyticsEvent,
  useTrace,
} from "@sushiswap/telemetry";
import {
  Button,
  Currency,
  DialogConfirm,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogProvider,
  DialogReview,
  DialogTitle,
  List,
  SkeletonBox,
  SkeletonText,
  buttonIconVariants,
  classNames,
} from "@sushiswap/ui";
import { log } from "next-axiom";
import React, {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSimulateTrade } from "src/lib/hooks/useSimulateTrade";
import { useSlippageTolerance } from "src/lib/hooks/useSlippageTolerance";
import { useBalanceWeb3Refetch } from "src/lib/wagmi/hooks/balances/useBalanceWeb3Refetch";
import { useApproved } from "src/lib/wagmi/systems/Checker/Provider";
import { Chain, ChainId } from "sushi/chain";
import { Native, tryParseAmount, WETH9_ADDRESS } from "sushi/currency";
import { shortenAddress } from "sushi/format";
import { ZERO } from "sushi/math";
import { LiquidityProviders } from "sushi/router";
import {
  SendTransactionReturnType,
  UserRejectedRequestError,
  stringify,
} from "viem";
import {
  useAccount,
  usePublicClient,
  useSendTransaction,
  useWaitForTransactionReceipt,
} from "wagmi";
import { APPROVE_TAG_SWAP } from "../../../lib/constants";
import {
  warningSeverity,
  warningSeverityClassName,
} from "../../../lib/swap/warningSeverity";
import {
  useDerivedStateSimpleSwap,
  useOutSimpleSwapTrade,
  useSimpleSwapTrade,
} from "./derivedstate-simple-swap-provider";
import { SimpleSwapErrorMessage } from "./simple-swap-error-message";
import CollapsibleIcon from "./collapsible";
import GasIcon from "./_icons/gas-icon";
import { SlippageToleranceStorageKey, useLocalStorage } from "@sushiswap/hooks";

export const SimpleSwapTradeReviewDialog: FC<{
  children({
    error,
    isSuccess,
  }: {
    error: Error | null;
    isSuccess: boolean;
  }): ReactNode;
}> = ({ children }) => {
  const {
    state: { token0, token1, chainId, swapAmount, recipient },
    mutate: { setSwapAmount },
  } = useDerivedStateSimpleSwap();

  const { approved } = useApproved(APPROVE_TAG_SWAP);
  const [slippagePercent, { isAuto: isAutoSlippage }] = useSlippageTolerance();
  const { data: inTrade } = useSimpleSwapTrade();
  const { data: outTrade } = useOutSimpleSwapTrade();

  const trade = inTrade ?? outTrade;

  const { address, chain } = useAccount();
  const tradeRef = useRef<UseTradeReturn | null>(null);
  const client = usePublicClient();

  const parsedValue = useMemo(
    () => tryParseAmount(trade?.amountIn?.toSignificant(), token0),
    [token0, trade]
  );

  const refetchBalances = useBalanceWeb3Refetch();

  const { data: price, isInitialLoading: isPriceLoading } = usePrice({
    chainId: chainId,
    address: token0?.isNative
      ? WETH9_ADDRESS[chainId]
      : token0?.id.split(":")[1],
  });

  const isWrap =
    token0?.isNative &&
    token1?.wrapped.address === Native.onChain(chainId).wrapped.address;
  const isUnwrap =
    token1?.isNative &&
    token0?.wrapped.address === Native.onChain(chainId).wrapped.address;
  const isSwap = !isWrap && !isUnwrap;

  const [big, portion] = (
    parsedValue && price
      ? `${price.asFraction
          .multiply(parsedValue)
          .divide(10 ** parsedValue.currency.decimals)
          .toFixed(2)}`
      : "0.00"
  ).split(".");

  const {
    data: simulation,
    isError,
    error,
    isFetching: isPrepareFetching,
    isSuccess: isPrepareSuccess,
  } = useSimulateTrade({
    trade,
    enabled: Boolean(
      approved && chain?.id === chainId && token1?.chainId === chainId
    ),
  });

  useEffect(() => {
    if (!error) return;

    console.error("swap prepare error", error);
    const message = error.message.toLowerCase();
    if (
      message.includes("user rejected") ||
      message.includes("user cancelled")
    ) {
      return;
    }

    sendAnalyticsEvent(SwapEventName.SWAP_ESTIMATE_GAS_CALL_FAILED, {
      route: stringify(trade?.route),
      slippageTolerance: slippagePercent.toPercentageString(),
      error: error.message,
    });

    log.error("swap prepare error", {
      route: stringify(trade?.route),
      slippageTolerance: slippagePercent.toPercentageString(),
      error: stringify(error),
    });
  }, [error, slippagePercent, trade?.route]);

  const trace = useTrace();

  const onSwapSuccess = useCallback(
    async (hash: SendTransactionReturnType) => {
      if (!trade || !chainId) return;

      try {
        const ts = new Date().getTime();
        const receiptPromise = client.waitForTransactionReceipt({ hash });

        sendAnalyticsEvent(SwapEventName.SWAP_SIGNED, {
          ...trace,
          route: stringify(trade?.route),
          txHash: hash,
        });

        void createToast({
          account: address,
          type: "swap",
          chainId: chainId,
          txHash: hash,
          promise: receiptPromise,
          summary: {
            pending: `${
              isWrap ? "Wrapping" : isUnwrap ? "Unwrapping" : "Swapping"
            } ${trade.amountIn?.toSignificant(6)} ${
              trade.amountIn?.currency.symbol
            } ${
              isWrap ? "to" : isUnwrap ? "to" : "for"
            } ${trade.amountOut?.toSignificant(6)} ${
              trade.amountOut?.currency.symbol
            }`,
            completed: `${
              isWrap ? "Wrap" : isUnwrap ? "Unwrap" : "Swap"
            } ${trade.amountIn?.toSignificant(6)} ${
              trade.amountIn?.currency.symbol
            } ${
              isWrap ? "to" : isUnwrap ? "to" : "for"
            } ${trade.amountOut?.toSignificant(6)} ${
              trade.amountOut?.currency.symbol
            }`,
            failed: `Something went wrong when trying to ${
              isWrap ? "wrap" : isUnwrap ? "unwrap" : "swap"
            } ${trade.amountIn?.currency.symbol} ${
              isWrap ? "to" : isUnwrap ? "to" : "for"
            } ${trade.amountOut?.currency.symbol}`,
          },
          timestamp: ts,
          groupTimestamp: ts,
        });

        const receipt = await receiptPromise;
        {
          const trade = tradeRef.current;
          if (receipt.status === "success") {
            sendAnalyticsEvent(SwapEventName.SWAP_TRANSACTION_COMPLETED, {
              txHash: hash,
              from: receipt.from,
              chain_id: chainId,
              route: stringify(trade?.route),
              tx: stringify(trade?.tx),
            });
            if (
              trade?.route?.legs?.every(
                (leg) =>
                  leg.poolName.startsWith("Wrap") ||
                  leg.poolName.startsWith(LiquidityProviders.SushiSwapV2) ||
                  leg.poolName.startsWith(LiquidityProviders.SushiSwapV3)
              )
            ) {
              log.info("internal route", {
                chainId: chainId,
                txHash: hash,
                exporerLink: Chain.txUrl(chainId, hash),
                route: stringify(trade?.route),
                tx: stringify(trade?.tx),
              });
            } else if (
              trade?.route?.legs?.some(
                (leg) =>
                  !leg.poolName.startsWith("Wrap") &&
                  (leg.poolName.startsWith(LiquidityProviders.SushiSwapV2) ||
                    leg.poolName.startsWith(LiquidityProviders.SushiSwapV3))
              ) &&
              trade?.route?.legs?.some(
                (leg) =>
                  !leg.poolName.startsWith("Wrap") &&
                  (!leg.poolName.startsWith(LiquidityProviders.SushiSwapV2) ||
                    !leg.poolName.startsWith(LiquidityProviders.SushiSwapV3))
              )
            ) {
              log.info("mix route", {
                chainId: chainId,
                txHash: hash,
                exporerLink: Chain.txUrl(chainId, hash),
                route: stringify(trade?.route),
                tx: stringify(trade?.tx),
              });
            } else if (
              trade?.route?.legs?.every(
                (leg) =>
                  leg.poolName.startsWith("Wrap") ||
                  (!leg.poolName.startsWith(LiquidityProviders.SushiSwapV2) &&
                    !leg.poolName.startsWith(LiquidityProviders.SushiSwapV3))
              )
            ) {
              log.info("external route", {
                chainId: chainId,
                txHash: hash,
                exporerLink: Chain.txUrl(chainId, hash),
                route: stringify(trade?.route),
                tx: stringify(trade?.tx),
              });
            } else {
              log.info("unknown", {
                chainId: chainId,
                txHash: hash,
                exporerLink: Chain.txUrl(chainId, hash),
                route: stringify(trade?.route),
                tx: stringify(trade?.tx),
              });
            }
          } else {
            sendAnalyticsEvent(SwapEventName.SWAP_TRANSACTION_FAILED, {
              txHash: hash,
              from: receipt.from,
              chain_id: chainId,
              route: stringify(trade?.route),
              tx: stringify(trade?.tx),
            });
            if (
              trade?.route?.legs?.every(
                (leg) =>
                  leg.poolName.startsWith("Wrap") ||
                  leg.poolName.startsWith(LiquidityProviders.SushiSwapV2) ||
                  leg.poolName.startsWith(LiquidityProviders.SushiSwapV3)
              )
            ) {
              log.error("internal route", {
                chainId: chainId,
                txHash: hash,
                route: stringify(trade?.route),
                tx: stringify(trade?.tx),
              });
            } else if (
              trade?.route?.legs?.some(
                (leg) =>
                  !leg.poolName.startsWith("Wrap") &&
                  (leg.poolName.startsWith(LiquidityProviders.SushiSwapV2) ||
                    leg.poolName.startsWith(LiquidityProviders.SushiSwapV3))
              ) &&
              trade?.route?.legs?.some(
                (leg) =>
                  !leg.poolName.startsWith("Wrap") &&
                  (!leg.poolName.startsWith(LiquidityProviders.SushiSwapV2) ||
                    !leg.poolName.startsWith(LiquidityProviders.SushiSwapV3))
              )
            ) {
              log.error("mix route", {
                chainId: chainId,
                txHash: hash,
                route: stringify(trade?.route),
                tx: stringify(trade?.tx),
              });
            } else if (
              trade?.route?.legs?.every(
                (leg) =>
                  leg.poolName.startsWith("Wrap") ||
                  (!leg.poolName.startsWith(LiquidityProviders.SushiSwapV2) &&
                    !leg.poolName.startsWith(LiquidityProviders.SushiSwapV3))
              )
            ) {
              log.error("external route", {
                chainId: chainId,
                txHash: hash,
                route: stringify(trade?.route),
                tx: stringify(trade?.tx),
              });
            } else {
              log.error("unknown", {
                chainId: chainId,
                txHash: hash,
                route: stringify(trade?.route),
                tx: stringify(trade?.tx),
              });
            }
          }
        }
      } finally {
        setSwapAmount("");
        await refetchBalances();
      }
    },
    [
      setSwapAmount,
      trade,
      chainId,
      client,
      address,
      isWrap,
      isUnwrap,
      refetchBalances,
      trace,
    ]
  );

  const onSwapError = useCallback(
    (e: Error) => {
      if (e.cause instanceof UserRejectedRequestError) {
        return;
      }

      sendAnalyticsEvent(SwapEventName.SWAP_ERROR, {
        route: stringify(trade?.route),
        tx: stringify(trade?.tx),
        error: e instanceof Error ? e.message : undefined,
      });

      log.error("swap error", {
        route: stringify(trade?.route),
        tx: stringify(trade?.tx),
        error: stringify(e),
      });
      createErrorToast(e.message, false);
    },
    [trade?.route, trade?.tx]
  );

  const {
    sendTransactionAsync,
    isPending: isWritePending,
    data,
  } = useSendTransaction({
    mutation: {
      onMutate: () => {
        // Set reference of current trade
        if (tradeRef && trade) {
          tradeRef.current = trade;
        }
      },
      onSuccess: onSwapSuccess,
      onError: onSwapError,
    },
  });

  const write = useMemo(() => {
    if (!sendTransactionAsync || !simulation) return undefined;

    return async (confirm: () => void) => {
      try {
        await sendTransactionAsync(simulation);
        confirm();
      } catch {}
    };
  }, [simulation, sendTransactionAsync]);

  const { status } = useWaitForTransactionReceipt({
    chainId: chainId,
    hash: data,
  });

  const [showMore, setShowMore] = useState(false);

  const baseCurrency = useMemo(() => {
    return inTrade
      ? inTrade?.swapPrice?.baseCurrency?.symbol
      : outTrade?.swapPrice?.baseCurrency.symbol;
  }, [inTrade, outTrade]);

  const quoteCurrency = useMemo(() => {
    return inTrade
      ? inTrade?.swapPrice?.quoteCurrency?.symbol
      : outTrade?.swapPriceInverse?.baseCurrency?.symbol;
  }, [inTrade, outTrade]);

  const priceAmount = useMemo(() => {
    return inTrade
      ? trade?.swapPrice?.toSignificant(6)
      : outTrade?.swapPriceInverse?.toSignificant(6);
  }, [inTrade, outTrade]);

  return (
    <Trace modal={InterfaceModalName.CONFIRM_SWAP}>
      <DialogProvider>
        <DialogReview>
          {({ confirm }) => (
            <>
              <div className="flex flex-col">
                <SimpleSwapErrorMessage
                  error={error}
                  isSuccess={isPrepareSuccess}
                  isLoading={isPrepareFetching}
                />
                <div>{children({ error, isSuccess: isPrepareSuccess })}</div>
              </div>
              <DialogContent className="p-6 pt-4">
                <div className="py-2">
                  <div className="text-white text-lg font-bold">
                    Review Swap
                  </div>
                </div>
                <DialogHeader></DialogHeader>
                <div className="space-y-2">
                  <div className="rounded-xl">
                    <div className="flex justify-between items-center">
                      <div>
                        <p className="text-sm text-white/20">Sell</p>
                        <p className="text-3xl font-bold text-white">
                          {trade?.amountIn?.toSignificant(6)} {token0?.symbol}
                        </p>
                        <p className="text-sm text-white/20"></p>
                      </div>
                      {token0 && (
                        <Currency.Icon
                          width={40}
                          height={40}
                          className={buttonIconVariants({ size: "default" })}
                          currency={token0}
                          disableLink
                        />
                      )}
                    </div>
                  </div>

                  <div className="py-4 rounded-xl">
                    <div className="flex justify-between items-center">
                      <div>
                        <p className="text-sm text-white/20">Buy</p>
                        <p className="text-3xl font-bold text-white">
                          {trade?.amountOut?.toSignificant(6)} {token1?.symbol}
                        </p>
                        <p className="text-sm text-white/20"></p>
                      </div>
                      {token1 && (
                        <Currency.Icon
                          width={40}
                          height={40}
                          className={buttonIconVariants({ size: "default" })}
                          currency={token1}
                          disableLink
                        />
                      )}
                    </div>
                  </div>

                  <div
                    onClick={() => setShowMore(!showMore)}
                    className="w-full flex justify-between cursor-pointer hover:opacity-80"
                  >
                    <div>
                      <button className="text-sm text-white font-semibold hover:text-white/80 transition-colors duration-200 ease-in-out flex items-center">
                        {showMore ? "Show less" : "Show more"}
                      </button>
                    </div>
                    <div>
                      <CollapsibleIcon
                        state={showMore ? "expanded" : "collapsed"}
                      />
                    </div>
                  </div>

                  <div className="space-y-2 bg-white/10 p-4  border border-white/20 rounded-xl">
                    {/* <div className="flex justify-between text-sm">
                      <span className="text-white/40 font-semibold">Rate</span>
                      <span className="text-white/80 font-semibold">
                        1 {token0?.symbol} ={" "}
                        {trade?.amountOut
                          ?.divide(trade?.amountIn ?? 1n)
                          .toSignificant(6)}{" "}
                        {token1?.symbol}
                      </span>
                    </div> */}
                    <div className="flex justify-between text-sm">
                      <span className="text-white/40">Rate</span>
                      <span className="text-sm text-white/80 font-semibold">
                        1 {baseCurrency} = {priceAmount} {quoteCurrency} ($
                        {big}.{portion})
                      </span>
                    </div>
                    {showMore && (
                      <>
                        <div className="flex justify-between text-sm">
                          <span className="text-white/40">Price impact</span>
                          <span
                            className={
                              "font-semibold " +
                              (trade?.priceImpact?.lessThan(ZERO)
                                ? "text-green"
                                : "text-red")
                            }
                          >
                            {`${
                              trade?.priceImpact?.lessThan(ZERO)
                                ? "+"
                                : trade?.priceImpact?.greaterThan(ZERO)
                                ? "-"
                                : ""
                            }${Math.abs(
                              Number(trade?.priceImpact?.toFixed(2))
                            )}%`}
                          </span>
                        </div>

                        <div className="flex justify-between text-sm">
                          <span className="text-white/40">Max slippage</span>
                          <div className="flex items-center">
                            {isAutoSlippage && (
                              <div className="rounded-[28px] bg-white/20 h-[20px] w-[39px] flex items-center justify-center font-medium text-white text-[10px] mr-1">
                                Auto
                              </div>
                            )}
                            <span className="text-white font-semibold">
                              {slippagePercent.toPercentageString()}
                            </span>
                          </div>
                        </div>

                        <div className="flex justify-between text-sm">
                          <span className="text-white/40">
                            Receive at least
                          </span>
                          <span className="text-white font-semibold">
                            {trade?.minAmountOut?.toSignificant(6)}{" "}
                            {trade?.minAmountOut?.currency.symbol}
                          </span>
                        </div>
                      </>
                    )}
                    <div className="flex justify-between text-sm">
                      <span className="text-white/40">Fee (0.25%)</span>
                      <span className="text-white font-bold">{trade?.fee}</span>
                    </div>
                    <div className="flex justify-between text-sm">
                      <span className="text-white/40">Network Cost</span>
                      <span className="text-white/40 flex items-center gap-1">
                        <GasIcon />
                        <span>
                          {trade?.gasSpent} {Native.onChain(chainId).symbol}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
                <DialogFooter>
                  <div className="flex flex-col gap-4 w-full mt-4">
                    <TraceEvent
                      events={[BrowserEvent.onClick]}
                      element={InterfaceElementName.CONFIRM_SWAP_BUTTON}
                      name={SwapEventName.SWAP_SUBMITTED_BUTTON_CLICKED}
                      properties={{
                        route: stringify(trade?.route),
                        ...trace,
                      }}
                    >
                      <Button
                        fullWidth
                        size="xl"
                        loading={!write && !isError}
                        onClick={() => write?.(confirm)}
                        disabled={Boolean(
                          !!error ||
                            isWritePending ||
                            Boolean(
                              !sendTransactionAsync &&
                                swapAmount?.greaterThan(ZERO)
                            ) ||
                            isError
                        )}
                        color={
                          isError
                            ? "red"
                            : warningSeverity(trade?.priceImpact) >= 3
                            ? "red"
                            : "blue"
                        }
                        testId="confirm-swap"
                      >
                        {isError
                          ? "Shoot! Something went wrong :("
                          : isWrap
                          ? "Wrap"
                          : isUnwrap
                          ? "Unwrap"
                          : `Confirm Swap`}
                      </Button>
                    </TraceEvent>
                  </div>
                </DialogFooter>
              </DialogContent>
            </>
          )}
        </DialogReview>
        <DialogConfirm
          chainId={chainId}
          status={status}
          testId="make-another-swap"
          buttonText="Make another swap"
          txHash={data}
          successMessage={`You ${
            isWrap ? "wrapped" : isUnwrap ? "unwrapped" : "sold"
          } ${tradeRef.current?.amountIn?.toSignificant(6)} ${token0?.symbol} ${
            isWrap ? "to" : isUnwrap ? "to" : "for"
          } ${tradeRef.current?.amountOut?.toSignificant(6)} ${token1?.symbol}`}
        />
      </DialogProvider>
    </Trace>
  );
};
