import CircleDesign from "./_icons/circle-design";
import PointsIcon from "./_icons/points-icon";
import StarIcon from "./_icons/star-icon";

export default function SimpleSwapRewardsDisplay({
  points,
}: {
  points: number;
}) {
  return (
    <div className="flex items-center justify-between relative h-[52px] border border-white/20 rounded-lg px-3  bg-gradient-to-r from-black via-black to-[#0300A4]/20">
      <div className="absolute right-0 bottom-0 opacity-20">
        <CircleDesign />
      </div>
      <div className="flex items-center gap-2">
        <StarIcon />
        <span className="text-[12px] font-semibold text-white/80">
          Your Rewards
        </span>
      </div>
      <div className="flex items-center gap-2">
        <PointsIcon />
        <span className="text-[12px]">{points.toLocaleString()} PTS</span>
      </div>
    </div>
  );
}
