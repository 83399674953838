"use client";

import ArrowsUpDownIcon from "@heroicons/react/24/solid/ArrowsUpDownIcon";
import {
  BrowserEvent,
  InterfaceElementName,
  SwapEventName,
  TraceEvent,
} from "@sushiswap/telemetry";

import { useDerivedStateSimpleSwap } from "./derivedstate-simple-swap-provider";
import UpDownArrow from "./_icons/up-down-arrow";

export const SimpleSwapSwitchTokensButton = () => {
  const {
    mutate: { switchTokens },
  } = useDerivedStateSimpleSwap();

  return (
    <div className="left-0 right-0 lg:mt-[-26px] lg:mb-[-26px] flex items-center justify-center">
      <TraceEvent
        events={[BrowserEvent.onClick]}
        name={SwapEventName.SWAP_TOKENS_REVERSED}
        element={InterfaceElementName.SWAP_TOKENS_REVERSE_ARROW_BUTTON}
      >
        <button
          onClick={switchTokens}
          type="button"
          className="hover:shadow-sm transition-border z-10 group bg-black transition-all rounded-full cursor-pointer flex items-center justify-center h-7 w-7"
        >
          <div className="transition-transform rotate-0 group-hover:rotate-180 border border-white/20 rounded-full h-6 w-6 flex items-center justify-center">
            <UpDownArrow />
          </div>
        </button>
      </TraceEvent>
    </div>
  );
};
